import React, { FC } from 'react';
import classNames from 'classnames';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';

import { DangerouslySetInnerHtml } from 'layout';

import HomepageTypes from '../../../@types/content/homepage';

const TwoColumnPromoRow: FC<HomepageTypes.IPromoRowsItem> = ({
  properties: {
    title,
    description,
    button,
    imageBackground,
    imagePackshot,
    textBgColor,
    packshotBgColor,
    bGVersion,
  },
  inFirstViewport,
}) => {
  const bgImage = imageBackground?.[0].properties?.image;
  const packshotImage = imagePackshot?.[0].properties?.image;
  const isWhiteBGVersion = !!Number(bGVersion);

  return (
    <div className="two-column-promo__row">
      <div
        className={classNames('row-text', {
          'row-text--white-version': isWhiteBGVersion,
        })}
        style={{ backgroundColor: textBgColor }}
      >
        {title ? <h3>{title}</h3> : null}
        {description ? (
          <DangerouslySetInnerHtml className="row-text__description" html={description} />
        ) : null}
        {button[0] ? (
          <Button
            btnColorVariant={isWhiteBGVersion ? 'outline-dark' : 'always-dark'}
            link={button[0].properties.buttonLink[0].url}
            ariaLabel={button[0].properties.ariaLabel}
          >
            {button[0].properties.buttonText}
          </Button>
        ) : null}
      </div>
      <div className="row-media">
        {bgImage?.gatsbyImage?.childImageSharp && !isWhiteBGVersion ? (
          <GatsbyImage
            alt={imageBackground?.[0].properties?.imageAltLabel}
            fluid={bgImage?.gatsbyImage?.childImageSharp?.fluid}
            wrapperClasses="row-image__background"
            isLazyLoading={!inFirstViewport}
          />
        ) : null}
        <div
          className={
            isWhiteBGVersion ? 'row-image__packshot--white-version' : 'row-image__packshot'
          }
          style={{ backgroundColor: packshotBgColor }}
        >
          {packshotImage?.gatsbyImage?.childImageSharp ? (
            <GatsbyImage
              wrapperClasses={
                isWhiteBGVersion
                  ? 'row-image__product row-image__product--white-version'
                  : 'row-image__product'
              }
              alt={imagePackshot?.[0]?.properties?.imageAltLabel}
              fluid={packshotImage?.gatsbyImage?.childImageSharp?.fluid}
              isLazyLoading={!inFirstViewport}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default TwoColumnPromoRow;
