import React, { FC } from 'react';
import classNames from 'classnames';

import { IconProps } from './models';

const Icon: FC<IconProps> = ({ icon, style, className }) => (
  <span
    {...{
      className: classNames('icon', className, {
        [`icon-${icon}`]: icon,
      }),
      style,
    }}
  />
);

export default Icon;
