import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { Container, DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';

import { IHeroBannerProductIntroProps } from './models';
import './HeroBannerProductIntro.scss';

const HeroBannerProductIntro: FC<IHeroBannerProductIntroProps> = ({
  altImageText,
  image,
  title,
  button,
  inFirstViewport,
  products,
  imageHelper,
  imageHelperAlt,
}) => {
  const { isTablet, isDesktop } = useScreenRecognition();

  return (
    <Container fluid>
      <div className="hero-banner-product-intro">
        <div className="hero-banner-product-intro__image-bg-wrapper">
          {image?.childImageSharp ? (
            <GatsbyImage
              isLazyLoading={!inFirstViewport}
              fluid={image?.childImageSharp.fluid}
              alt={altImageText}
              fadeIn={!inFirstViewport}
              objectPosition="50% 50%"
            />
          ) : null}
        </div>
        <Container className="hero-banner-product-intro__content">
          <div className="hero-banner-product-intro__left">
            <div className="hero-banner-product-intro__product">
              {products?.[0]?.properties?.image?.gatsbyImage?.childImageSharp ? (
                <GatsbyImage
                  isLazyLoading={!inFirstViewport}
                  fluid={products?.[0]?.properties?.image?.gatsbyImage?.childImageSharp.fluid}
                  alt={products?.[0]?.properties?.imageAlt}
                  fadeIn={!inFirstViewport}
                />
              ) : null}
              <DangerouslySetInnerHtml
                element="h2"
                className="hero-banner-product-intro__product-title"
                html={products?.[0]?.properties?.title}
              />
            </div>
          </div>

          <div className="hero-banner-product-intro__center">
            {(isTablet || isDesktop) &&
              (imageHelper?.gatsbyImage?.childImageSharp ? (
                <div className="hero-banner-product-intro__logo">
                  <GatsbyImage
                    isLazyLoading={!inFirstViewport}
                    fluid={imageHelper?.gatsbyImage?.childImageSharp.fluid}
                    alt={imageHelperAlt}
                    fadeIn={!inFirstViewport}
                  />
                </div>
              ) : null)}
            <h1 className="hero-banner-product-intro__title">{title}</h1>

            {button?.[0]?.properties ? (
              <Button
                link={button[0].properties.buttonLink[0].url}
                btnColorVariant="white"
                ariaLabel={button[0].properties.ariaLabel}
              >
                {button[0].properties.buttonText}
              </Button>
            ) : null}
          </div>

          <div className="hero-banner-product-intro__right">
            <div className="hero-banner-product-intro__product">
              {products?.[1]?.properties?.image?.gatsbyImage?.childImageSharp ? (
                <GatsbyImage
                  isLazyLoading={!inFirstViewport}
                  fluid={products?.[1]?.properties?.image?.gatsbyImage?.childImageSharp.fluid}
                  alt={products?.[1]?.properties?.imageAlt}
                  fadeIn={!inFirstViewport}
                />
              ) : null}
              <DangerouslySetInnerHtml
                element="h2"
                className="hero-banner-product-intro__product-title"
                html={products?.[1]?.properties?.title}
              />
            </div>
          </div>
        </Container>
      </div>
    </Container>
  );
};

export default HeroBannerProductIntro;
