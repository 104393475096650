import React, { FC } from 'react';
import TwoColumnPromoRow from './TwoColumnPromoRow';
import { ITwoColumnPromoProps } from './model';
import './TwoColumnPromo.scss';
import './TwoColumnPromoExtends.scss';
import './TwoColumnPromoRTL.scss';

const TwoColumnPromo: FC<ITwoColumnPromoProps> = ({ rows = [], inFirstViewport }) =>
  rows?.length > 0 ? (
    <div className="two-column-promo" data-testid="two-column-promo">
      {rows.map(({ properties }, index) => {
        const id = `${properties?.title}_${index}`;

        return (
          <TwoColumnPromoRow
            key={id}
            properties={properties}
            inFirstViewport={typeof inFirstViewport === 'boolean' ? inFirstViewport : index === 0}
          />
        );
      })}
    </div>
  ) : null;
export default TwoColumnPromo;
