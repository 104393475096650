import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { Col, Container, Row, DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import useYotpo from 'hooks/useYotpo';

import { IReviewsPanelProps } from './model';
import './ReviewsPanel.scss';

const ReviewsPanel: FC<IReviewsPanelProps> = ({
  yotpoReviewsCarouselTitle,
  yotpoReviewsCarouselCode,
  yotpoReviewsCarouselButton,
}) => {
  const { isYotpoInitialized } = useYotpo();

  return (
    <div className="reviews-panel">
      <Container>
        <Row>
          <Col sm="12" md="12" lg="12" xl="12">
            <h2 className="reviews-panel__title">{yotpoReviewsCarouselTitle}</h2>
            <div
              className={classNames(`reviews-panel__code`, {
                active: isYotpoInitialized,
              })}
            >
              <DangerouslySetInnerHtml html={yotpoReviewsCarouselCode} />
            </div>

            {yotpoReviewsCarouselButton?.[0].properties ? (
              <div className="reviews-panel__button-wrapper">
                <Button
                  btnColorVariant="outline-white"
                  link={yotpoReviewsCarouselButton?.[0].properties.buttonLink[0].url}
                >
                  {yotpoReviewsCarouselButton?.[0].properties.buttonText}
                </Button>
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentReviewsPanel on IYotpoReviewsCarouselData {
    yotpoReviewsCarouselTitle
    yotpoReviewsCarouselCode
    yotpoReviewsCarouselButton {
      ...FragmentButton
    }
  }
`;

export default ReviewsPanel;
