import React, { FC, useState, useEffect, useCallback, useRef, useContext } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import NextArrow from 'common/ControlledCarousel/subcomponents/NextArrow';
import PrevArrow from 'common/ControlledCarousel/subcomponents/PrevArrow';
import CustomDots from 'common/ControlledCarousel/subcomponents/CustomDots';
import { CommonSettingsContext } from 'layout/Layout/Layout';
import { commonSettingsUtils } from 'utils';
import CustomDot from './CustomDots';

import { ControlledCarouselProps, CarouselItem, INavigatorButtons, NavigatorProps } from './model';
import './ControlledCarousel.scss';

const ControlledCarousel: FC<ControlledCarouselProps> = ({
  children,
  classes = '',
  showDots = false,
  showNavigators = false,
  navigatorsOutside = false,
  dotsOutside = false,
  responsiveObj,
  customDots,
  infinite = false,
  showAllDots,
  nextIcon,
  prevIcon,
  onChangeAction,
}) => {
  const commonSettings: any = useContext(CommonSettingsContext);
  const waiSettings = commonSettingsUtils.getSettingsPropertiesByStructure(commonSettings, 'WAI');

  const [activeSlide, setActiveSlide] = useState(0);
  const [width, setWidth] = useState(0);
  const [isLessContent, setIsLessContent] = useState<boolean>(false);
  const [slidesToShow, setSlidesToShow] = useState<number | undefined>(undefined);
  const [navigatorProps, setNavigatorProps] = useState<NavigatorProps>({
    controller: null,
    allItemsCount: 0,
    activeSlide: 0,
  });
  const thisCarousel = useRef<CarouselItem | any>(null);
  const controllerProps = navigatorProps.controller?.props;
  const controllerState = navigatorProps.controller?.state;
  const childrenToRender = children.filter((child) => child);
  const hasGutter =
    controllerProps?.responsive[controllerState.deviceType]?.partialVisibilityGutter;
  const setActiveSlideHandler = useCallback(
    (index: number) => {
      setActiveSlide(index);
      if (onChangeAction) {
        onChangeAction();
      }
    },
    [activeSlide]
  );

  const carouselHandleResize = () =>
    setIsLessContent(slidesToShow === undefined || childrenToRender?.length <= slidesToShow);

  const updateWidth = (e) => {
    setWidth(e.target?.innerWidth);
    carouselHandleResize();
  };

  useEffect(() => {
    carouselHandleResize();
    setNavigatorProps({
      controller: thisCarousel.current,
      allItemsCount: childrenToRender?.length,
      activeSlide,
    });
  }, [width]);

  useEffect(() => {
    childrenToRender?.length > 0 && updateWidth(thisCarousel);
    setSlidesToShow(thisCarousel.current?.state.slidesToShow);
  }, [navigatorProps]);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const NavigatorButtons: FC<INavigatorButtons> = ({ next, previous }) =>
    !isLessContent && showNavigators && previous !== undefined && next !== undefined ? (
      <div className="carousel__navigators">
        <PrevArrow {...navigatorProps} ariaLabel={waiSettings?.prevAria} customIcon={prevIcon} />
        <NextArrow {...navigatorProps} ariaLabel={waiSettings?.nextAria} customIcon={nextIcon} />
      </div>
    ) : null;

  return (
    <>
      {childrenToRender?.length > 0 ? (
        <div className={classes}>
          <Carousel
            ssr
            showDots={showDots && !isLessContent && !showAllDots}
            renderDotsOutside={dotsOutside}
            renderButtonGroupOutside={navigatorsOutside}
            centerMode={isLessContent && childrenToRender?.length !== 1 && !hasGutter}
            partialVisible={!isLessContent && childrenToRender?.length > 1 && hasGutter}
            beforeChange={setActiveSlideHandler}
            className="carousel"
            responsive={responsiveObj}
            arrows={false}
            swipeable
            itemClass={`${classes}__item`}
            ref={thisCarousel}
            infinite={infinite}
            customButtonGroup={<NavigatorButtons />}
            customDot={customDots ? <CustomDot customDots={customDots} /> : null}
          >
            {childrenToRender}
          </Carousel>
          {customDots && showAllDots && showDots ? (
            <CustomDots
              customDots={customDots}
              activeSlide={activeSlide}
              onClick={thisCarousel?.current?.goToSlide}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};
export default ControlledCarousel;
