import React, { FC, useCallback } from 'react';

import CustomDot from '../../CustomDots';

import { ICustomDotsProps } from './model';

const CustomDots: FC<ICustomDotsProps> = ({ customDots, activeSlide, onClick }) => {
  const move = onClick ? useCallback((to) => () => onClick(to), []) : () => {};

  return (
    <div className="react-multi-carousel-dot-list" data-testid="react-multi-carousel-dot-list">
      {customDots?.map((_, idx) => (
        <CustomDot
          key={Math.random()}
          index={idx}
          onClick={move(idx)}
          active={activeSlide === idx}
          customDots={customDots}
        />
      ))}
    </div>
  );
};
export default CustomDots;
