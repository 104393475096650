import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import IconCustom from 'common/IconCustom';
import Button from 'common/Button';

import { NavigatorProps } from 'common/ControlledCarousel/model';

const PrevArrow: FC<NavigatorProps> = ({ controller, ariaLabel, customIcon }) => {
  const currentSlide = controller?.state?.currentSlide || 0;
  const isDisabled = currentSlide === 0;
  const isDisabledWithInfinite = controller?.props?.infinite ? false : isDisabled;

  const movePrev = useCallback(() => {
    controller?.previous();
  }, [controller]);

  return (
    <Button
      ariaLabel={ariaLabel}
      type="button"
      disabled={isDisabledWithInfinite}
      handler={movePrev}
      classes={classNames('carousel-navigator', 'carousel-navigator__prev', {
        'carousel-navigator--disabled': isDisabledWithInfinite,
      })}
    >
      <IconCustom icon={customIcon || 'Arrow-slajder-selector-dark'} />
    </Button>
  );
};
export default PrevArrow;
