import React from 'react';
import HeroBanner from 'gatsby-theme-husky/src/components/ArticleListingHeroBanner';
import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import HPCarousel from 'gatsby-theme-husky/src/components/HPCarousel';
import useScreenRecognition from 'hooks/useScreenRecognition';

const HomePageContent = ({
  hpCarousel,
  gatsbyImage,
  imageAlt,
  title,
  description,
  button,
  bannerTheme,
  homepageBlocks,
  bodyStructure,
  relatedArticles,
  relatedProducts,
  imageMobile,
}) => {
  const { isMobile } = useScreenRecognition();
  const specificImage = isMobile && imageMobile ? imageMobile : gatsbyImage;

  return (
    <div className="home-page">
      {hpCarousel.length === 1 ? (
        <HeroBanner
          // @ts-ignore
          inFirstViewport
          image={specificImage}
          altImageText={imageAlt}
          title={title}
          description={description}
          hasVerticalPadding={false}
          button={button}
          className="article-listing-hero-banner"
          bannerTheme={bannerTheme}
        />
      ) : (
        <HPCarousel inFirstViewport items={hpCarousel || []} />
      )}
      {homepageBlocks?.length > 0 ? (
        <BodyRenderer
          bodyData={homepageBlocks}
          bodyStructure={bodyStructure}
          bodyItemProps={{ relatedArticles, relatedProducts }}
        />
      ) : null}
    </div>
  );
};

export default HomePageContent;
