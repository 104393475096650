import React, { FC } from 'react';
import className from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';

import { IFullWidthPromoProps } from './model';
import './FullWidthPromo.scss';
import './FullWidthPromoExtends.scss';
import './FullWidthPromoRTL.scss';

const FullWidthPromo: FC<IFullWidthPromoProps> = ({
  title,
  cta = [],
  bodyColor,
  body,
  sectionTheme,
}) => {
  return (
    <div
      className={className('full-width-promo', {
        [`${sectionTheme}`]: sectionTheme,
      })}
    >
      {title ? <h2 className="full-width-promo__title">{title}</h2> : null}
      {body ? (
        <DangerouslySetInnerHtml
          html={body}
          className={className('full-width-promo__body', {
            [`full-width-promo__body--${bodyColor}`]: bodyColor,
          })}
        />
      ) : null}
      {cta?.length > 0 ? (
        <div className="full-width-promo__links">
          {cta.map(({ properties: { buttonLink, buttonText, buttonColor } }) =>
            buttonLink?.[0].url ? (
              <Button key={buttonText} btnColorVariant={buttonColor[0]} link={buttonLink?.[0].url}>
                {buttonText}
              </Button>
            ) : null
          )}
        </div>
      ) : null}
    </div>
  );
};
export default FullWidthPromo;
