import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';
import { Container, DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { IHeroBannerThreeProductsIntroProps } from './models';
import './HeroBannerThreeProductsIntro.scss';
import './HeroBannerThreeProductsIntroRTL.scss';

const HeroBannerThreeProductsIntro: FC<IHeroBannerThreeProductsIntroProps> = ({
  altImageText,
  image,
  button,
  inFirstViewport,
  products,
  imageHelper,
  imageHelperAlt,
  titleRTE,
}) => {
  const { isTablet, isDesktop } = useScreenRecognition();

  return (
    <Container fluid>
      <div className="hero-banner-three-products-intro">
        <div className="hero-banner-three-products-intro__image-bg-wrapper">
          {image?.childImageSharp ? (
            <GatsbyImage
              isLazyLoading={!inFirstViewport}
              fluid={image?.childImageSharp.fluid}
              alt={altImageText}
              fadeIn={!inFirstViewport}
            />
          ) : null}
        </div>
        <div className="hero-banner-three-products-intro__content">
          <div className="hero-banner-three-products-intro__left">
            {(isTablet || isDesktop) && imageHelper?.gatsbyImage?.childImageSharp ? (
              <div className="hero-banner-three-products-intro__logo">
                <GatsbyImage
                  isLazyLoading={!inFirstViewport}
                  fluid={imageHelper?.gatsbyImage?.childImageSharp.fluid}
                  alt={imageHelperAlt}
                  fadeIn={!inFirstViewport}
                />
              </div>
            ) : null}
            <DangerouslySetInnerHtml
              className="hero-banner-three-products-intro__title"
              html={titleRTE}
            />

            {button?.[0]?.properties ? (
              <Button
                link={button[0].properties.buttonLink[0].url}
                btnColorVariant="white"
                ariaLabel={button[0].properties.ariaLabel}
              >
                {button[0].properties.buttonText}
              </Button>
            ) : null}
          </div>

          <div className="hero-banner-three-products-intro__right">
            <div className="hero-banner-three-products-intro__product">
              {products?.[0]?.properties?.image?.gatsbyImage?.childImageSharp ? (
                <GatsbyImage
                  isLazyLoading={!inFirstViewport}
                  fluid={products?.[0]?.properties?.image?.gatsbyImage?.childImageSharp.fluid}
                  alt={products?.[0]?.properties?.imageAlt}
                  fadeIn={!inFirstViewport}
                  objectFit="contain"
                />
              ) : null}
              <DangerouslySetInnerHtml
                className="hero-banner-three-products-intro__product-title"
                html={products?.[0]?.properties?.title}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HeroBannerThreeProductsIntro;
