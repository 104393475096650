import React, { FC } from 'react';
import classNames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { Container, DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';

import { IHeroBannerOneProductRightIntro } from './models';
import './HeroBannerOneProductRightIntro.scss';
import './HeroBannerOneProductRightIntroRTL.scss';

const HeroBannerOneProductRightIntro: FC<IHeroBannerOneProductRightIntro> = ({
  altImageText,
  image,
  button,
  inFirstViewport,
  products,
  imageHelper,
  imageHelperAlt,
  titleRTE,
  bannerType,
}) => {
  const { isTablet, isDesktop } = useScreenRecognition();

  return (
    <Container fluid>
      <div
        className={classNames('hero-banner-one-product-right-intro', {
          gold: bannerType?.[0] === 'oneProductRightIntroGold',
        })}
      >
        <div className="hero-banner-one-product-right-intro__image-bg-wrapper">
          {image?.childImageSharp ? (
            <GatsbyImage
              isLazyLoading={!inFirstViewport}
              fluid={image?.childImageSharp.fluid}
              alt={altImageText}
              fadeIn={!inFirstViewport}
              objectPosition="100% 50%"
            />
          ) : null}
        </div>
        <Container className="hero-banner-one-product-right-intro__content">
          <div className="hero-banner-one-product-right-intro__left">
            {(isTablet || isDesktop) &&
              (imageHelper?.gatsbyImage?.childImageSharp ? (
                <div className="hero-banner-one-product-right-intro__logo">
                  <GatsbyImage
                    isLazyLoading={!inFirstViewport}
                    fluid={imageHelper?.gatsbyImage?.childImageSharp.fluid}
                    alt={imageHelperAlt}
                    fadeIn={!inFirstViewport}
                  />
                </div>
              ) : null)}
            <DangerouslySetInnerHtml
              html={titleRTE}
              className="hero-banner-one-product-right-intro__title"
            />

            {button?.[0]?.properties ? (
              <Button
                link={button[0].properties.buttonLink[0].url}
                btnColorVariant="dark"
                ariaLabel={button[0].properties.ariaLabel}
              >
                {button[0].properties.buttonText}
              </Button>
            ) : null}
          </div>

          <div className="hero-banner-one-product-right-intro__right">
            <div className="hero-banner-one-product-right-intro__product">
              {products?.[0]?.properties?.image?.gatsbyImage?.childImageSharp ? (
                <GatsbyImage
                  isLazyLoading={!inFirstViewport}
                  fluid={products?.[0]?.properties?.image?.gatsbyImage?.childImageSharp.fluid}
                  alt={products?.[0]?.properties?.imageAlt}
                  fadeIn={!inFirstViewport}
                />
              ) : null}
              <DangerouslySetInnerHtml
                element="h2"
                className="hero-banner-one-product-right-intro__product-title"
                html={products?.[0]?.properties?.title}
              />
            </div>
          </div>
        </Container>
      </div>
    </Container>
  );
};

export default HeroBannerOneProductRightIntro;
