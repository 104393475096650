import React, { FC } from 'react';

import ControlledCarousel from 'common/ControlledCarousel';
import HeroBanner from 'components/HeroBanner';
import HeroBannerProductIntro from 'components/HeroBannerProductIntro';
import HeroBannerOneProductRightIntro from 'components/HeroBannerOneProductRightIntro';
import HeroBannerThreeProductsIntro from 'components/HeroBannerThreeProductsIntro';
import useScreenRecognition from 'hooks/useScreenRecognition';

import responsiveObj from './constants';
import { IHPCarousel } from './model';
import './HPCarousel.scss';
import './HPCarouselExtends.scss';

const HPCarousel: FC<IHPCarousel> = ({ items, inFirstViewport }) => {
  const { isMobile, isTablet } = useScreenRecognition();

  return (
    <ControlledCarousel
      showDots
      infinite
      showNavigators
      classes="hp-carousel-carousel"
      responsiveObj={responsiveObj}
    >
      {items.map(
        (
          {
            properties: {
              title,
              description,
              button,
              image,
              imageSm,
              imageMd,
              imageAlt,
              bannerType,
              products,
              imageHelper,
              imageHelperAlt,
              titleRTE,
            },
          },
          idx
        ) => {
          const id = `${title}_${idx}`;

          let specificImage = image;

          if (isMobile && imageSm) {
            specificImage = imageSm;
          } else if (isTablet && imageMd) {
            specificImage = imageMd;
          }

          const heroBannerProductIntroComponents = {
            twoProductsIntro: HeroBannerProductIntro,
            oneProductRightIntro: HeroBannerOneProductRightIntro,
            oneProductRightIntroGold: HeroBannerOneProductRightIntro,
            threeProductsRightIntro: HeroBannerThreeProductsIntro,
          };

          const HeroBannerProductIntroComponent = heroBannerProductIntroComponents[bannerType?.[0]];

          if (HeroBannerProductIntroComponent) {
            return (
              <HeroBannerProductIntroComponent
                key={id}
                title={title}
                button={button}
                image={specificImage.gatsbyImage}
                altImageText={imageAlt}
                inFirstViewport={inFirstViewport}
                products={products}
                imageHelper={imageHelper}
                imageHelperAlt={imageHelperAlt}
                titleRTE={titleRTE}
                bannerType={bannerType}
              />
            );
          }

          return (
            <HeroBanner
              key={id}
              description={description}
              title={title}
              button={button}
              image={specificImage.gatsbyImage}
              altImageText={imageAlt}
              inFirstViewport={inFirstViewport}
            />
          );
        }
      )}
    </ControlledCarousel>
  );
};
export default HPCarousel;
