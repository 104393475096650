const responsiveObj = {
  superLargeDesktop: {
    breakpoint: { max: 6000, min: 1700 },
    items: 1,
  },
  largeDesktop: {
    breakpoint: { max: 1700, min: 1128 },
    items: 1,
  },
  middleDesktop: {
    breakpoint: { max: 1128, min: 900 },
    items: 1,
  },
  smallTablet: {
    breakpoint: { max: 900, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 500 },
    items: 1,
  },
  smallMobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
  },
};

export { responsiveObj as default };
