import { useState, useEffect, useMemo } from 'react';

// const YOTPO_SCRIPT_ID = 'yotpoScript';
// eslint-disable-next-line prefer-destructuring
// const GATSBY_YOTPO_API_KEY = process.env.GATSBY_YOTPO_API_KEY;

// if (!GATSBY_YOTPO_API_KEY && process.env.NODE_ENV !== 'test') {
//   throw new Error('looks like required ENV variable "GATSBY_YOTPO_API_KEY" are missed');
// }

const useYotpo = () => {
  const [isYotpoInitialized, setIsYotpoInitialized] = useState<boolean>(false);

  // const connectYotpoScript = useMemo(
  //   () => () => {
  //     if (typeof document === 'undefined') return;
  //     setTimeout(() => {
  //       const isScriptTagAlreadyConnected = !!document.getElementById(YOTPO_SCRIPT_ID);

  //       if (isScriptTagAlreadyConnected) return;

  //       const yotpoScript = document.createElement('script');
  //       yotpoScript.type = 'text/javascript';
  //       yotpoScript.async = !0;
  //       yotpoScript.id = YOTPO_SCRIPT_ID;
  //       yotpoScript.src = `//staticw2.yotpo.com/${GATSBY_YOTPO_API_KEY}/widget.js`;

  //       document.head.appendChild(yotpoScript);
  //     }, 3000);
  //   },
  //   [YOTPO_SCRIPT_ID, GATSBY_YOTPO_API_KEY]
  // );

  const connectYotpoWidgets = useMemo(
    () => () => {
      if (typeof window === 'undefined') return;

      const { yotpo } = window;

      if (yotpo) {
        if (yotpo.initialized) {
          yotpo.refreshWidgets();
        } else {
          yotpo.initWidgets();
        }

        setIsYotpoInitialized(true);
      }
    },
    []
  );

  useEffect(() => {
    // connectYotpoScript();
    connectYotpoWidgets();
  }, []);

  return {
    isYotpoInitialized,
    updateYotpo: connectYotpoWidgets,
  };
};

export default useYotpo;
